import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ZH from '@/language/zh.js'  //中文
import EN from '@/language/en.js'  //英文
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    'zh': ZH,
    'en': EN
  }
})
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
